import { Children, useRef, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { ControlBack, ControlNext } from '@dx-ui/osc-controls';
import cx from 'classnames';
import { useEventListener, useResizeObserver, useIsClient } from 'usehooks-ts';
import { isRtl } from '@dx-ui/utilities-get-language-direction';
import { useRouter } from 'next/router';

const TIMEOUT = 500;

export type TaxonomyListProps = {
  labelledBy: string;
  testId?: string;
  name: string;
  listItemClassname?: string;
};

const updateScrollDataAttributes = (scrollingRegion: HTMLUListElement) => {
  if (!scrollingRegion) {
    return;
  }

  const visibleWidth = scrollingRegion.clientWidth;
  const maxScroll = scrollingRegion.scrollWidth - visibleWidth;
  const scrollPosition = Math.abs(Math.round(scrollingRegion.scrollLeft));

  scrollingRegion.setAttribute('data-at-start', scrollPosition <= 1 ? 'true' : 'false');

  const endOfScrollingRegion = scrollPosition >= maxScroll - 1;
  scrollingRegion.setAttribute('data-at-end', endOfScrollingRegion ? 'true' : 'false');
};

export const TaxonomyList: React.FC<React.PropsWithChildren<TaxonomyListProps>> = ({
  labelledBy,
  name,
  testId = 'taxonomy-list',
  children,
  listItemClassname,
}) => {
  const { t } = useTranslation('osc-taxonomy-list');
  const isClient = useIsClient();
  const { locale = 'en' } = useRouter();
  const rtl = isRtl(locale);

  const arrayChildren = Children.toArray(children);

  const scrollingRegionRef = useRef<React.ElementRef<'ul'>>(null);

  const handleScrollClick = (direction: number) => {
    const scrollingRegion = scrollingRegionRef.current;
    if (!scrollingRegion) {
      return;
    }
    const visibleWidth = scrollingRegionRef?.current?.clientWidth;
    scrollingRegion.scrollLeft += visibleWidth * direction;
    setTimeout(() => {
      updateScrollDataAttributes(scrollingRegion);
    }, TIMEOUT);
  };

  useEventListener(
    'scroll',
    (event) => updateScrollDataAttributes(event.target as HTMLUListElement),
    scrollingRegionRef
  );

  const listSize = useResizeObserver({ ref: scrollingRegionRef, box: 'border-box' });

  const isListScrollable = useMemo(() => {
    const getScrollableList = (scrollingRegion: HTMLUListElement | null) => {
      if (!scrollingRegion && !listSize) {
        return false;
      }

      return listSize.width && scrollingRegion
        ? scrollingRegion.scrollWidth > Math.round(listSize.width)
        : false;
    };

    return getScrollableList(scrollingRegionRef?.current) || false;
  }, [listSize]);

  const shouldDisplayScrollableList = isListScrollable && isClient;

  return (
    <div className="relative">
      <ul
        className={cx('peer flex py-3 overflow-x-auto', {
          'px-5 md:px-10 snap-proximity snap-x focus-visible:outline outline-[3px] outline-hilton motion-safe:scroll-smooth':
            shouldDisplayScrollableList,
        })}
        ref={scrollingRegionRef}
        tabIndex={0}
        aria-labelledby={labelledBy}
        data-testid={testId}
        data-at-start={true}
        data-at-end={false}
      >
        {Children.map(arrayChildren, (child, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index} className={listItemClassname}>
            {child}
          </li>
        ))}
      </ul>
      {shouldDisplayScrollableList ? (
        <>
          <ControlBack
            className={cx(
              'w-11 rounded-none absolute flex justify-start rtl:justify-end items-center start-0 top-1/2 -translate-y-1/2 h-full bg-gradient-to-r from-50% from-bg',
              // Fake a disabled state for mouse users. Clicking the button at the start of the data won't do anything, but shouldn't be disabled for accessibility.
              'peer-data-[at-start=true]:cursor-not-allowed peer-data-[at-start=true]:text-text-disabled peer-data-[at-start=true]:focus:!shadow-none'
            )}
            onClick={() => handleScrollClick(rtl ? 1 : -1)}
            label={t('previousSetOfItems', { itemName: name })}
            data-direction={rtl ? 'forward' : 'backward'}
          />
          <ControlNext
            className={cx(
              'w-11 rounded-none flex justify-end rtl:justify-start items-center absolute end-0 top-1/2 -translate-y-1/2 h-full bg-gradient-to-l from-50% from-bg',
              // Fake a disabled state for mouse users. Clicking the button at the end of the data won't do anything, but shouldn't be disabled for accessibility.
              'peer-data-[at-end=true]:cursor-not-allowed peer-data-[at-end=true]:text-text-disabled peer-data-[at-end=true]:focus:!shadow-none'
            )}
            onClick={() => handleScrollClick(rtl ? -1 : 1)}
            label={t('nextSetOfItems', { itemName: name })}
            data-direction={rtl ? 'backward' : 'forward'}
          />
        </>
      ) : null}
    </div>
  );
};

export default TaxonomyList;
