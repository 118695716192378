import { keepPreviousData, type UseQueryOptions } from '@tanstack/react-query';
import type { ShopMultiPropAvailQueryInput } from '../../../gql/types';
import type {
  ShopMultiPropAvailQueryVariables,
  ShopMultiPropAvailQuery,
} from '../../../gql/operations';
import { ShopMultiPropAvailPointsDocument, ShopMultiPropAvailDocument } from '../../../gql/queries';

export const generateMPAQueryArray = ({
  chunkedMPA,
  enabled,
  input,
  language,
}: {
  chunkedMPA: string[][];
  enabled: boolean;
  input: ShopMultiPropAvailQueryInput;
  language: string;
}) => {
  const queryKey =
    input?.specialRates?.hhonors === true
      ? ShopMultiPropAvailPointsDocument
      : ShopMultiPropAvailDocument;

  return chunkedMPA.reduce((arr, chunk) => {
    const chunkedVariables: ShopMultiPropAvailQueryVariables = {
      input,
      ctyhocns: chunk,
      language,
    };

    arr.push({
      queryKey: [queryKey, chunkedVariables],
      enabled,
      placeholderData: keepPreviousData,
      retry: 2,
      staleTime: 1000 * 60 * 10,
    });
    return arr;
  }, [] as UseQueryOptions<ShopMultiPropAvailQuery>[]);
};
