import { TabList, TabListButton, TabPanel, TabPanels, Tabs } from '@dx-ui/osc-tabs';
import type * as React from 'react';
import { getMonthsYearDetails } from './flex-dates-utils';
import { useWrappedRouter } from '../../../hooks';
import { DEFAULT_LANGUAGE } from '@dx-ui/utilities-dates';

type MonthSliderProp = {
  ctyhocn: string;
  children: React.ReactNode;
  onMonthChange: (tabIndex: number) => void;
};
export const MonthSlider = ({ children, onMonthChange }: MonthSliderProp) => {
  const { router } = useWrappedRouter();
  const monthYear = getMonthsYearDetails(router.locale || DEFAULT_LANGUAGE);

  return (
    <div>
      <Tabs defaultActive="0" onTabChange={(index) => onMonthChange(parseFloat(index || '0'))}>
        <TabList className="gap-x-4">
          {monthYear.map(({ monthYear, unabbreviatedMonthYear }, index) => (
            <TabListButton id={`${index}`} key={monthYear} aria-label={unabbreviatedMonthYear}>
              <span aria-hidden className="text-wrap text-xs font-bold sm:text-base">
                {monthYear}
              </span>
            </TabListButton>
          ))}
        </TabList>
        <TabPanels>
          {monthYear?.map(({ monthYear }, panelIndex) => {
            return (
              <TabPanel className="py-4" id={`${panelIndex}`} key={monthYear}>
                {children}
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </div>
  );
};
