import type { HotelTripAdvisorLocation } from '@dx-ui/gql-types';

import { useTranslation } from 'next-i18next';
import Image from 'next/legacy/image';
import cx from 'classnames';

type TARating = Pick<
  HotelTripAdvisorLocation,
  'ratingFmt' | 'ratingImageUrl' | 'numReviews'
> | null;

type TripAdvisorRatingProps = {
  className?: string;
  isLoadingState?: boolean;
  showReviewCount?: boolean;
  tripAdvisorLocationSummary?: TARating;
  isCompare?: boolean;
};

export const TripAdvisorRating = ({
  isLoadingState,
  showReviewCount = false,
  tripAdvisorLocationSummary,
  isCompare,
}: TripAdvisorRatingProps) => {
  const { t } = useTranslation('trip-advisor');

  const numReviewsTxt = showReviewCount ? tripAdvisorLocationSummary?.numReviews : undefined;
  const formattedRate = tripAdvisorLocationSummary?.ratingFmt;
  const ratingImageUrl = tripAdvisorLocationSummary?.ratingImageUrl;
  const formattedRateText = t('imgAlt', { rating: formattedRate });

  if (isLoadingState) return <TripAdvisorShimmerState />;

  return formattedRate ? (
    <>
      <div
        className={cx({
          'flex flex-col-reverse': isCompare,
        })}
      >
        {ratingImageUrl ? (
          <div
            className={cx('relative -ml-1.5 h-4', {
              'w-24': !isCompare,
              'w-28': isCompare,
            })}
            data-testid="ta-rating-image"
          >
            <Image
              alt={formattedRateText}
              aria-hidden
              className="absolute inset-0 object-cover"
              layout="fill"
              src={ratingImageUrl}
            />
            <span className="sr-only">{formattedRateText}</span>
          </div>
        ) : null}
        <p
          aria-hidden
          className={cx({
            'text-xs font-semibold': isCompare,
            'text-sm font-bold': !isCompare,
          })}
        >
          {t('ratingInfoWithLabel', { rating: formattedRate })}
        </p>
      </div>
      {numReviewsTxt ? (
        <div className="text-text-alt text-xs font-normal">
          <span>{t('basedOnReviews', { count: numReviewsTxt })}</span>
        </div>
      ) : null}
    </>
  ) : null;
};

const TripAdvisorShimmerState = () => (
  <div data-testid="trip-advisor-rating-shimmer" className="flex flex-col">
    <div className="bg-border-alt mb-1 h-4 w-16 animate-pulse" />
    <div className="bg-border-alt mb-1 h-4 w-16 animate-pulse" />
    <div className="bg-border-alt mb-1 h-4 w-16 animate-pulse" />
  </div>
);
