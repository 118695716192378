import { CalendarDay } from '@dx-ui/osc-calendar';
import {
  getDaysSplitByWeeks,
  useDaysOfWeek,
  isKanji,
  useDateFormat,
  DEFAULT_LANGUAGE,
} from '@dx-ui/utilities-dates';
import cx from 'classnames';
import {
  addDays,
  format,
  isAfter,
  isBefore,
  isFirstDayOfMonth,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  lastDayOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
} from 'date-fns';
import * as React from 'react';
import { getRateDetails, type FlexDatesShopAvailCalendar } from './flex-dates-utils';
import { FlexDatesDayPrice } from './flex-dates-day-price';
import { useTranslation } from 'next-i18next';

type CalendarMonth = {
  /**
   * selected day or start day when a range is allowed
   */
  day?: Date;
  /**
   * selected end day when a range is allowed
   */
  endDay?: Date;
  /**
   * furthest date available to book
   */
  maxDays?: number;
  /**
   * allow ability to select previous months
   */
  allowPrevious?: boolean;
  /**
   * month to display, should be passed as the first day of the month, can use `startOfMonth(someDate)` from the date-fns lib
   */
  month?: Date;
  /**
   * locale for i18n
   */
  locale: Intl.Locale;
  /**
   * callback when a day is selected
   */
  onDayChange: (day?: Date) => void;
  focusedDay?: number;
  onFocusedDayChange: (d: number, newD?: number) => void;
  isVisibleMonth?: boolean;
  dayLabel?: (d: Date, isStartDay?: boolean) => string;
  /**
   * marked as current date, can be set to any date
   */
  today?: Date;
  /**
   * If using a string, it must be in the `yyyy-MM-dd` format
   */
  highlightedDates?: Array<Date | string>;
  /**
   * If using a string, it must be in the `yyyy-MM-dd` format
   */
  enabledDates?: Array<Date | string>;
  isPriceLoading?: boolean;
  dailyPrice: Record<string, FlexDatesShopAvailCalendar>;
  shouldUsePoints?: boolean;
  statusCode?: number | undefined | null;
} & React.HTMLAttributes<HTMLDivElement>;

const FORMAT = 'yyyy-MM-dd';

/**
 * displays a calendar month
 */
const PriceCalendar = React.forwardRef<HTMLDivElement, CalendarMonth>(
  (
    {
      day,
      endDay,
      maxDays = 730,
      allowPrevious,
      month: passedMonth,
      onDayChange,
      locale,
      className,
      onFocusedDayChange,
      isVisibleMonth,
      dayLabel,
      today = new Date(),
      highlightedDates: passedHighlightedDates = [],
      enabledDates: passedEnabledDates = [],
      dailyPrice,
      isPriceLoading,
      shouldUsePoints,
      statusCode,
    },
    forwardedRef
  ) => {
    const { t } = useTranslation('rate-name-cta');
    const month = passedMonth || startOfMonth(today);
    const dateToString = (d: Date | string) => {
      const isDate = d instanceof Date;
      const parsedDate = isDate ? ['', '', ''] : d.split('-');
      const date: Date = isDate
        ? d
        : new Date(
            parseInt(parsedDate[0] ?? '', 10),
            parseInt(parsedDate[1] ?? '', 10) - 1,
            parseInt(parsedDate[2] ?? '', 10)
          );
      return format(date, FORMAT);
    };

    const highlightedDates = React.useMemo(
      () => passedHighlightedDates.map(dateToString),
      [passedHighlightedDates]
    );

    const enabledDates = React.useMemo(
      () => passedEnabledDates.map(dateToString),
      [passedEnabledDates]
    );

    const weeks: Date[][] = React.useMemo(
      () => getDaysSplitByWeeks({ date: month, locale }),
      [month, locale]
    );
    const week = useDaysOfWeek({ locale });
    const formattedDate = useDateFormat({
      date: month,
      locale,
      options: { monthName: 'long' },
    });
    const header = isKanji(locale)
      ? `${formattedDate.year.numeric}${formattedDate.year.text} ${formattedDate.month.numeric}${formattedDate.month.text}`
      : `${formattedDate.month.text} ${formattedDate.year.numeric}`;
    const id = React.useId();
    const monthId = `calendar-month-${id}`;

    return (
      <div ref={forwardedRef} className={cx('w-full max-w-6xl select-none', className)}>
        <h2
          className="mb-2 block text-center text-lg font-extrabold"
          id={monthId}
          data-testid="calendar-month-header"
          aria-live="polite"
        >
          {header}
        </h2>
        <table role="grid" className="w-full" aria-labelledby={monthId}>
          <thead>
            <tr>
              {week.map((weekday) => (
                <th scope="col" key={weekday} className="text-center text-xs font-normal">
                  <span aria-hidden>{weekday}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {weeks.map((w, i) => (
              <tr key={w[0]?.getTime() ?? i}>
                {w.map((d) => {
                  if (d) {
                    const selected = (day && isSameDay(d, day)) || (endDay && isSameDay(d, endDay));
                    const tab0 =
                      selected || isSameDay(d, today) || (isVisibleMonth && isFirstDayOfMonth(d));
                    const disabled = enabledDates.length
                      ? !enabledDates.includes(format(d, FORMAT))
                      : allowPrevious
                      ? false
                      : isBefore(d, startOfDay(today)) || isAfter(d, addDays(today, maxDays));
                    const inRange =
                      day && endDay ? isWithinInterval(d, { start: day, end: endDay }) : false;
                    const highlighted = highlightedDates.includes(format(d, FORMAT));

                    const selectDay = () => onDayChange(d);
                    const onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
                      let flag = false;
                      switch (e.key) {
                        case ' ':
                        case 'Enter':
                          selectDay();
                          flag = true;
                          break;
                        case 'ArrowRight':
                          onFocusedDayChange(d.getTime(), addDays(d, 1).getTime());
                          break;
                        case 'ArrowLeft':
                          onFocusedDayChange(d.getTime(), subDays(d, 1).getTime());
                          flag = true;
                          break;
                        case 'ArrowDown':
                          onFocusedDayChange(d.getTime(), addDays(d, 7).getTime());
                          flag = true;
                          break;
                        case 'ArrowUp':
                          onFocusedDayChange(d.getTime(), subDays(d, 7).getTime());
                          flag = true;
                          break;
                        case 'Home':
                          onFocusedDayChange(d.getTime(), startOfWeek(d).getTime());
                          flag = true;
                          break;
                        case 'End':
                          onFocusedDayChange(d.getTime(), lastDayOfWeek(d).getTime());
                          flag = true;
                          break;
                        default:
                          break;
                      }
                      if (flag) {
                        e.stopPropagation();
                        e.preventDefault();
                      }
                    };
                    const currentDate = format(d, 'yyyy-MM-dd');
                    const price = dailyPrice[currentDate];
                    const { priceOrPoints, pointsAbbr, hasNoPriceOrPoints } = getRateDetails({
                      shopAvailCalendar: price,
                      shouldUsePoints,
                      isPriceFetching: isPriceLoading,
                      statusCode,
                      t,
                      locale: locale as unknown as string,
                    });
                    const isDisabled = disabled || hasNoPriceOrPoints || isPriceLoading;
                    return (
                      <td key={d.getTime()} className={cx('p-1', { group: !isDisabled })}>
                        {isSameMonth(d, month) ? (
                          <div
                            className={cx('group-hover:text-text-inverse group-hover:bg-primary', {
                              'group-hover:bg-tertiary': shouldUsePoints,
                            })}
                          >
                            <CalendarDay
                              id={`day-${d.getTime()}`}
                              disabled={disabled}
                              inRange={inRange}
                              highlighted={highlighted}
                              onKeyDown={onKeyDown}
                              onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
                                if (evt.clientX && evt.clientY) {
                                  selectDay();
                                }
                              }}
                              onFocus={() => onFocusedDayChange(d.getTime())}
                              tabIndex={tab0 ? 0 : -1}
                            >
                              <span className="flex flex-col items-center text-sm">
                                <span className="sr-only">{dayLabel ? dayLabel(d) : ''}</span>
                                <span aria-hidden>
                                  {d.toLocaleDateString(locale || DEFAULT_LANGUAGE, {
                                    day: 'numeric',
                                  })}
                                </span>
                                <FlexDatesDayPrice
                                  isDateDisabled={disabled}
                                  isPriceFetching={isPriceLoading}
                                  pointsAbbr={pointsAbbr}
                                  priceOrPoints={priceOrPoints}
                                  statusCode={statusCode}
                                  shouldUsePoints={shouldUsePoints}
                                />
                              </span>
                            </CalendarDay>
                          </div>
                        ) : null}
                      </td>
                    );
                  }
                  return null;
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
);

PriceCalendar.displayName = 'PriceCalendar';

export { PriceCalendar };
export default PriceCalendar;
