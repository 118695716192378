import cx from 'classnames';

type FlexDatesDayPriceProps = {
  isDateDisabled?: boolean;
  isPriceFetching?: boolean;
  shouldUsePoints?: boolean;
  statusCode?: number | undefined | null;
  priceOrPoints?: string;
  pointsAbbr?: string;
};

export const FlexDatesDayPrice = ({
  isDateDisabled,
  isPriceFetching,
  priceOrPoints,
  pointsAbbr,
  shouldUsePoints,
  statusCode,
}: FlexDatesDayPriceProps) => {
  const hasPrice = statusCode === 0;
  return (
    <>
      {isPriceFetching ? <span className="bg-bg-alt h-4 w-12 animate-pulse" /> : null}
      {!isPriceFetching && !isDateDisabled ? (
        <span
          className={cx(
            'text-primary normal-case text-2xs group-hover:text-text-inverse pb-1 text-wrap',
            {
              'text-tertiary': shouldUsePoints && hasPrice,
              'text-text-alt': !hasPrice,
            }
          )}
        >
          {shouldUsePoints ? pointsAbbr : priceOrPoints}
        </span>
      ) : null}
    </>
  );
};
