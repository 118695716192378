import { Status, useConductricsSelection } from '@dx-ui/framework-conductrics';
import { FLEX_DATES_MODAL } from '../../../../constants';
import { useMemo } from 'react';
import { useWrappedRouter } from '../../../../hooks';

export const useFlexDatesModalMVT = ({ enableABTest = false }: { enableABTest?: boolean }) => {
  const { router } = useWrappedRouter();
  const flexDatesModalTestStatus =
    enableABTest && router.asPath.includes('/locations/') ? Status.OK : Status.PENDING;
  const { isLoaded: isFlexDatesModalTestLoaded, selection: flexDateModalSelections } =
    useConductricsSelection(FLEX_DATES_MODAL.agentId, flexDatesModalTestStatus);

  const { isFlexDatesModalDefaultTestEnabled, isFlexDatesModalTestEnabled } = useMemo(() => {
    const variant =
      isFlexDatesModalTestLoaded && flexDatesModalTestStatus === Status.OK
        ? flexDateModalSelections?.choice?.toLowerCase()
        : '';
    return {
      isFlexDatesModalDefaultTestEnabled: variant === 'a',
      isFlexDatesModalTestEnabled: variant === 'b',
    };
  }, [flexDateModalSelections?.choice, flexDatesModalTestStatus, isFlexDatesModalTestLoaded]);
  return {
    isFlexDatesModalTestLoaded,
    isFlexDatesModalDefaultTestEnabled,
    isFlexDatesModalTestEnabled,
    isDefaultSelection: flexDateModalSelections?.choice?.toLowerCase() === 'a',
    isFlexDateModalSelection: flexDateModalSelections?.choice?.toLowerCase() === 'b',
  };
};
