import type { HotelBadgeTypes } from '../hotel-badges';
import type { RateMessage, RateInfoMessage } from '../../rate-name-cta/rate-name-cta';
import type { TALocationSummary } from '../../trip-advisor/trip-advisor-review';
import type {
  Maybe,
  Hotel,
  ShopMultiPropAvailPointsQuery,
  HotelImage,
  HotelImageRatio,
  HotelImageVariant,
} from '../../../gql/types';
import type { HotelType } from '../../../providers/app-provider/app-provider.types';
import { isAfter } from 'date-fns';
import type { TFunction } from 'i18next';

export type HotelInfoParams = {
  hotelInfo?: HotelType | Maybe<Hotel> | null | undefined;
  priceInfo?: ShopMultiPropAvailPointsQuery['shopMultiPropAvail'][0] | null | undefined;
  additionalParams?: { [key: string]: boolean | string | string[] | null | undefined };
};

export type HotelInfoAttributes = {
  badges?: HotelBadgeTypes[];
  carouselImages?:
    | (Pick<HotelImage, 'altText'> &
        {
          ratios?: Maybe<Pick<HotelImageRatio, 'url' | 'size'>[]>;
          variants?: Maybe<Pick<HotelImageVariant, 'url' | 'size'>[]>;
        }[])
    | undefined;
  ctaLabel?: RateMessage;
  ctaHref?: string;
  ctaMessage?: RateInfoMessage;
  customParams?: { [key: string]: string | boolean };
  disclaimerMessages?: RateInfoMessage[];
  hotelName?: string;
  hotelUrl?: string;
  isHotelDataLoading?: boolean;
  isNewHotel?: boolean;
  isSaleHotel?: boolean;
  isRenoHotel?: boolean;
  masterImage?: Pick<HotelImage, 'altText'> & {
    variants?: Pick<HotelImageVariant, 'url' | 'size'>[];
    ratios?: Maybe<Pick<HotelImageRatio, 'url' | 'size'>[]>;
  };
  messages?: RateInfoMessage[];
  rateInfo?: RateInfoMessage[];
  suppressBrandLogo?: boolean;
  transactionPropertyData?: HotelTransactionPropertyData;
  tripAdvisorLocationSummary?: TALocationSummary;
};

type HotelTransactionPropertyData = {
  propCode: string;
  srp: string;
  price: string;
  currency: string;
};

export const isLocalCurrencySameAsHotelCurrency = (
  selectedCurrency: string | null,
  hotelCurrency?: string
) => selectedCurrency === '' || selectedCurrency === hotelCurrency;

type buttonLabelsArg = {
  t: TFunction<['hotel-card', 'rate-name-cta', 'locations-page']>;
  isAdultsOnly?: boolean | null;
  isNoResEnabled?: boolean | null;
};
export const getButtonLabels = ({
  t,
  isAdultsOnly,
  isNoResEnabled,
}: buttonLabelsArg): Record<string, string> => ({
  AVAILABLE: t('viewRates'),
  MAY_BE_AVAILABLE: t('maybeAvailable'),
  NOT_OPEN: isNoResEnabled ? '' : t('selectDates'),
  NOT_AVAILABLE: isAdultsOnly ? '' : t('selectDates'),
});

type AvailabilityTextArgs = {
  t: TFunction<['hotel-card', 'rate-name-cta', 'locations-page']>;
  isPreSell?: boolean | null;
  isPreOpen?: boolean | null;
  isSoldOut?: boolean | null;
  openDate?: string | null;
  numChildren?: number;
  occupants?: string;
  preOpenMsg?: string | null;
  isOccupancyExceeded?: boolean;
  isIndividualRoomsOnly?: boolean;
};
type AvailabilityTextRetVal = {
  comingSoonAndSoldOutCaption?: string;
  comingSoonAndSoldOutMessage?: string;
};

const getPreAvailabilityText = ({
  t,
  isPreSell,
  openDate,
  preOpenMsg,
}: Pick<AvailabilityTextArgs, 't' | 'isPreSell' | 'isPreOpen' | 'openDate' | 'preOpenMsg'>) => {
  const caption = t('rate-name-cta:notOpen');
  const message = preOpenMsg
    ? preOpenMsg
    : isPreSell
    ? t('rate-name-cta:preSell', { preSellDate: openDate })
    : t('rate-name-cta:preOpen', { preOpenDate: openDate });

  return { comingSoonAndSoldOutCaption: caption, comingSoonAndSoldOutMessage: message };
};

const getSoldOutOrUnavailableText = ({
  t,
  isOccupancyExceeded,
  numChildren,
  occupants,
}: Pick<AvailabilityTextArgs, 't' | 'isOccupancyExceeded' | 'numChildren' | 'occupants'>) => {
  let caption: string | undefined = undefined;
  let message: string | undefined = undefined;

  if (isOccupancyExceeded) {
    caption = t('rate-name-cta:occupancyExceededCaption');
    message = t('rate-name-cta:occupancyExceededMsg', { numGuests: occupants });
  } else if (numChildren) {
    caption = t('rate-name-cta:adultsOnly');
    message = t('rate-name-cta:unableBookWithKid', { count: numChildren });
  } else {
    caption = t('rate-name-cta:soldOutCaption');
    message = t('rate-name-cta:notAvailable');
  }

  return { comingSoonAndSoldOutCaption: caption, comingSoonAndSoldOutMessage: message };
};

export const getAvailabilityText = (args: AvailabilityTextArgs): AvailabilityTextRetVal => {
  const {
    t,
    isPreSell,
    isPreOpen,
    isSoldOut,
    openDate,
    numChildren,
    occupants,
    preOpenMsg,
    isOccupancyExceeded,
    isIndividualRoomsOnly,
  } = args;

  if (isPreSell || isPreOpen) {
    return getPreAvailabilityText({ t, isPreSell, isPreOpen, openDate, preOpenMsg });
  }

  if (isIndividualRoomsOnly) {
    return {
      comingSoonAndSoldOutCaption: t('rate-name-cta:individualRooms'),
      comingSoonAndSoldOutMessage: t('rate-name-cta:individualRoomsMsg'),
    };
  }

  if (isSoldOut) {
    return getSoldOutOrUnavailableText({ t, isOccupancyExceeded, numChildren, occupants });
  }

  return {
    comingSoonAndSoldOutCaption: undefined,
    comingSoonAndSoldOutMessage: undefined,
  };
};

export const getPreSellPreOpenInfo = (
  language: string,
  isHotelPreOpen?: boolean | null,
  preOpenDate?: string | null,
  preSellDate?: string | null,
  resEnabled?: boolean | null
) => {
  const preSell = isHotelPreOpen && preSellDate ? new Date(preSellDate || '') : undefined;
  const preOpen = isHotelPreOpen && preOpenDate ? new Date(preOpenDate || '') : undefined;
  const isPreSell = preSell && isAfter(preSell, new Date());
  const isPreOpen = preOpen && isAfter(preOpen, new Date());
  const isNoResPreOpen = isPreOpen && !resEnabled;
  const isResPreSell = isPreSell && resEnabled;

  return {
    isResPreSell,
    isPreOpen,
    isNoResPreOpen,
    preSellDateFmt:
      isPreSell && preSell
        ? new Date(
            preSell.getUTCFullYear(),
            preSell.getUTCMonth(),
            preSell.getUTCDate()
          ).toLocaleDateString(language, { dateStyle: 'long' })
        : '',
    preOpenDateFmt:
      isPreOpen && preOpen
        ? new Date(
            preOpen.getUTCFullYear(),
            preOpen.getUTCMonth(),
            preOpen.getUTCDate()
          ).toLocaleDateString(language, { year: 'numeric', month: 'long' })
        : '',
  };
};

export const getRateInfoTextClassName = ({
  className,
  hasColumnLayout,
  isCaption,
  isCompare,
  textType,
  type,
}: {
  className?: string;
  hasColumnLayout?: boolean;
  isCaption?: boolean;
  isCompare?: boolean;
  textType?: 'prefix' | 'heading' | 'suffix' | '';
  type?: string;
}) => {
  let textClassName = className ?? '';
  if (type === 'DiamondRate') textClassName += ' text-primary text-xs';
  if (type === 'UsePoints') textClassName += ' text-tertiary';
  if (isCaption) {
    if (textType === 'heading') {
      textClassName += ' font-bold text-lg ';
      textClassName += hasColumnLayout ? 'md:text-xl' : 'md:text-2xl';
    }
    if (textType === 'prefix') textClassName += ' font-bold text-xs';
    if (textType === 'suffix') textClassName += ' text-xs';
  }
  if (!textClassName) textClassName = 'text-text-alt text-xs';
  return isCompare
    ? `text-left rtl:text-right ${textClassName}`
    : `text-right rtl:text-left ${textClassName}`;
};

export const getMPARateInfo = (
  numOfChildren: number,
  usePoints: boolean,
  isDateFlex: boolean,
  hasSpecialRate?: boolean,
  allowAdultsOnly?: boolean,
  priceInfo?: ShopMultiPropAvailPointsQuery['shopMultiPropAvail'][0] | null
) => {
  const isAdultsOnly =
    allowAdultsOnly &&
    ((priceInfo?.summary?.status?.type === 'NOT_AVAILABLE' && priceInfo?.statusCode === 2005) ||
      numOfChildren > 0);
  const isOccupancyExceeded = priceInfo?.statusCode === 2001;
  const hasConfidentialRate = priceInfo?.summary?.lowest?.ratePlan?.confidentialRates;
  const lengthOfStay = priceInfo?.lengthOfStay;
  const pointsAvailable = priceInfo?.summary?.hhonors?.dailyRmPointsRateFmt;
  const isDiamondRate = priceInfo?.summary?.lowest?.ratePlan?.specialRateType === 'diamond48';
  const rateMessage = isDateFlex
    ? undefined
    : isDiamondRate
    ? priceInfo?.summary?.hhonors?.ratePlan?.ratePlanName ||
      priceInfo?.summary?.lowest?.ratePlan?.ratePlanName
    : hasSpecialRate
    ? priceInfo?.summary?.lowest?.ratePlan?.ratePlanName
    : usePoints && pointsAvailable
    ? priceInfo?.summary?.hhonors?.ratePlan?.ratePlanName
    : priceInfo?.summary?.lowest?.ratePlan?.ratePlanName || '';

  const hasRateChange =
    usePoints && Boolean(pointsAvailable)
      ? priceInfo?.summary?.hhonors?.rateChangeIndicator
      : priceInfo?.summary?.lowest?.rateChangeIndicator;

  const showDisplayFeeTransparencySubMsg =
    !priceInfo?.summary?.lowest?.cmaTotalPriceIndicator &&
    !usePoints &&
    Boolean(priceInfo?.summary?.lowest?.feeTransparencyIndicator);

  return {
    isAdultsOnly,
    isOccupancyExceeded,
    hasConfidentialRate,
    rateMessage,
    hasRateChange,
    showDisplayFeeTransparencySubMsg,
    lengthOfStay,
    pointsAvailable,
    isDiamondRate,
    isAgeBasedPricing: !!priceInfo?.ageBasedPricing,
  };
};
