import type { QueryParameters } from '../../../constants';
import { env } from '../../../constants';
import type { ShopMultiPropAvailPointsQuery, ShopPropStatusType } from '../../../gql/types';
import type { PageType } from '../../../utils';
import { generateCTAFunnelUrl, getCMAPrice } from '../../../utils';
import type { MapLanguage } from '@dx-ui/framework-uri-builder';
import type { ConversionCurrencyOptions } from '@dx-ui/osc-currency-converter';
import { formatAndConvertPrice } from '@dx-ui/osc-currency-converter';

import {
  getAvailabilityText,
  getButtonLabels,
  getMPARateInfo,
  getPreSellPreOpenInfo,
  isLocalCurrencySameAsHotelCurrency,
} from './hotel-info-utils';
import type { RateInfoMessage, RateMessage } from '../../rate-name-cta/rate-name-cta';
import type { TFunction } from 'i18next';
import type { HotelCardData } from './hotel-lead-rate-utils';
import type { HotelInfoAttributes } from './hotel-info-utils';
import type { DXError } from '@dx-ui/types-graphql';

export function getMPAHotelRate({
  numOfChildren,
  usePoints,
  isDateFlex,
  hasSpecialRate,
  hotelData,
  priceInfo,
  t,
  locale,
  pageType,
  ctyhocn,
  queryParameters,
  selectedCurrency,
  hasConnectingRooms,
  isPointsExplorer,
  currenciesError,
  currenciesMap,
  isIndividualRoomsOnly = false,
}: {
  numOfChildren: number;
  usePoints: boolean;
  isDateFlex: boolean;
  hasSpecialRate: boolean | undefined;
  hotelData: HotelCardData;
  priceInfo?: ShopMultiPropAvailPointsQuery['shopMultiPropAvail'][0] | null | undefined;
  t: TFunction<['hotel-card', 'rate-name-cta']>;
  locale: string;
  pageType: PageType;
  ctyhocn: string | undefined;
  queryParameters: QueryParameters | undefined;
  selectedCurrency: string | null;
  hasConnectingRooms: boolean;
  isPointsExplorer: boolean;
  currenciesError: DXError | null;
  currenciesMap: Map<string, ConversionCurrencyOptions>;
  isIndividualRoomsOnly?: boolean;
}): HotelInfoAttributes {
  const {
    isAdultsOnly,
    isOccupancyExceeded,
    hasConfidentialRate,
    rateMessage,
    hasRateChange,
    lengthOfStay,
    pointsAvailable,
    isDiamondRate,
    showDisplayFeeTransparencySubMsg,
  } = getMPARateInfo(
    numOfChildren,
    usePoints,
    isDateFlex,
    hasSpecialRate,
    Boolean(hotelData?.facilityOverview?.allowAdultsOnly),
    priceInfo
  );
  const ageBasedMessage = priceInfo?.ageBasedPricing ? t('ageBased') : undefined;
  const hotelMinPoints = hotelData?.leadRate?.hhonors?.min?.dailyRmPointsRateRoundFmt;
  const hotelMaxPoints = hotelData?.leadRate?.hhonors?.max?.dailyRmPointsRateRoundFmt;
  const pointsRange =
    hotelMinPoints && hotelMaxPoints ? `${hotelMinPoints} - ${hotelMaxPoints}` : '';

  //Status
  const status =
    isAdultsOnly || isOccupancyExceeded ? 'NOT_AVAILABLE' : priceInfo?.summary?.status?.type || '';

  const { isResPreSell, preSellDateFmt, isNoResPreOpen, isPreOpen, preOpenDateFmt } =
    getPreSellPreOpenInfo(
      locale,
      status === 'NOT_OPEN',
      hotelData?.display?.openDate,
      hotelData?.display?.resEnabledDate,
      hotelData?.display?.resEnabled
    );
  const href =
    isNoResPreOpen || isOccupancyExceeded
      ? ''
      : generateCTAFunnelUrl({
          availStatus: status ? (status as ShopPropStatusType) : 'LOADING',
          host: env.HONORS_HOST,
          isDreams: !!pageType?.isDreams,
          ctyhocn: ctyhocn as string,
          queryParameters,
          shouldUsePoints: usePoints,
          currencyCode: selectedCurrency as string,
          preOpenDate: (isResPreSell
            ? hotelData?.display?.resEnabledDate
            : isPreOpen
            ? hotelData?.display?.openDate
            : '') as string,
          hasConnectingRooms,
          isPointsExplorer,
          lang: locale as MapLanguage,
        });

  const { price, showAvgPricingMessage, totalRate, totalRateFmt, priceFmt } = getCMAPrice({
    cmaTotalPriceIndicator: priceInfo?.summary?.lowest?.cmaTotalPriceIndicator,
    rateAmount: priceInfo?.summary?.lowest?.rateAmount || 0,
    rateAmountFmt: priceInfo?.summary?.lowest?.rateAmountFmt || '',
    totalRate: priceInfo?.summary?.lowest?.amountAfterTax || 0,
    totalRateFmt: priceInfo?.summary?.lowest?.amountAfterTaxFmt || '',
    lengthOfStay,
  });

  // converted currency values
  const convertedTotalRate = isDateFlex
    ? undefined
    : isLocalCurrencySameAsHotelCurrency(
        selectedCurrency,
        hotelData?.localization?.currencyCode || ''
      )
    ? totalRateFmt
    : formatAndConvertPrice({
        basePrice: !totalRate ? undefined : totalRate,
        currencyQueryError: !!currenciesError,
        fromCurrency: currenciesMap?.get('USD'),
        language: locale,
        toCurrency: currenciesMap?.get(selectedCurrency as string),
      }) || totalRateFmt;

  const convertedPrice =
    isDateFlex || hasConfidentialRate
      ? undefined
      : isLocalCurrencySameAsHotelCurrency(
          selectedCurrency,
          hotelData?.localization?.currencyCode || ''
        )
      ? priceFmt
      : formatAndConvertPrice({
          basePrice: !price ? undefined : price,
          currencyQueryError: !!currenciesError,
          fromCurrency: currenciesMap?.get('USD'),
          language: locale,
          toCurrency: currenciesMap?.get(selectedCurrency as string),
        }) || priceFmt;
  const { comingSoonAndSoldOutCaption, comingSoonAndSoldOutMessage } = getAvailabilityText({
    t,
    isPreSell: isResPreSell,
    isPreOpen,
    isSoldOut: hotelData?.display?.open && status === 'NOT_AVAILABLE',
    openDate: isResPreSell ? preSellDateFmt : isPreOpen ? preOpenDateFmt : undefined,
    numChildren: isAdultsOnly ? numOfChildren : undefined,
    occupants: queryParameters
      ? (queryParameters?.numAdults + queryParameters?.numChildren).toString()
      : '',
    preOpenMsg: hotelData?.display?.preOpenMsg,
    isOccupancyExceeded,
    isIndividualRoomsOnly,
  });

  const showPointsInfo = !!(!hasSpecialRate && usePoints && pointsAvailable);

  //CTA Label
  const buttonLabel = isDateFlex
    ? t('flexible')
    : isPointsExplorer
    ? t('selectDates')
    : isAdultsOnly || isOccupancyExceeded || isIndividualRoomsOnly
    ? undefined
    : getButtonLabels({ t, isAdultsOnly, isNoResEnabled: isNoResPreOpen })[status];
  const ctaLabel = buttonLabel
    ? ({
        text: buttonLabel,
        screenReaderText: t('opensInNewTab'),
        type: showPointsInfo || isPointsExplorer ? 'UsePoints' : undefined,
      } as RateMessage)
    : undefined;

  const isPriceAvailable = status === 'AVAILABLE';
  const isSaleHotel = priceInfo?.summary?.lowest?.ratePlan?.attributes?.includes('sale');

  let isNewHotel = false;
  if (hotelData?.amenities)
    isNewHotel = !!hotelData?.amenities?.find((amenity) => amenity.id === 'newHotel');
  else if (hotelData?.amenityIds)
    isNewHotel = !!hotelData?.amenityIds?.find((amenity) => amenity === 'newHotel');

  const tripAdvisorLocationSummary = hotelData?.tripAdvisorLocationSummary || null;

  const messages: RateInfoMessage[] = [];

  const message = isPointsExplorer ? t('pointsPerNight').toUpperCase() : rateMessage;
  // Rate info caption - price value or comming soon/ sold out/ adults only/ max guest
  const defaultRateCaption = comingSoonAndSoldOutCaption || convertedPrice || '';
  //Rate name or comming soon/ sold out/ adults only/ max guest text
  const defaultRateMessage = comingSoonAndSoldOutMessage || message || '';

  const getUsePointsRateInfoMessage = () => {
    let pointsText = '';
    if (hasSpecialRate) {
      pointsText =
        lengthOfStay && lengthOfStay > 1 && hasRateChange
          ? t('payWithPointsFirstNight', { points: pointsAvailable })
          : t('payWithPointsPerNight', { points: pointsAvailable });
    } else {
      pointsText =
        lengthOfStay && lengthOfStay > 1 && hasRateChange
          ? t('pointsForFirstNight', { points: pointsAvailable })
          : t('pointsPerNight', { points: pointsAvailable });
    }
    messages.push(
      {
        type: hasSpecialRate ? undefined : isPriceAvailable ? 'UsePoints' : undefined,
        prefix: { text: ageBasedMessage ? t('ageBasedFrom') : undefined },
        heading: {
          text: (hasSpecialRate ? defaultRateCaption : pointsAvailable) as string,
          isCaption: true,
        },
        suffix: !hasSpecialRate ? { text: pointsText } : undefined,
      },
      {
        heading: { text: defaultRateMessage },
      }
    );
    if (ageBasedMessage)
      messages.push({
        heading: {
          text: ageBasedMessage,
          infoIconOrPopup: { align: 'left', isIcon: true },
          className: 'text-xs',
        },
      });
    if (hasSpecialRate)
      messages.push({
        heading: { text: pointsText, className: 'text-xs font-bold' },
        type: isPriceAvailable ? 'UsePoints' : undefined,
      });
  };

  const getRateCaptionSuffix = () => {
    if (showAvgPricingMessage && !usePoints) return t('average');
    if (isDiamondRate) return ` | ${t('roomAvailable')}`;
    return;
  };

  switch (true) {
    case isPointsExplorer:
      messages.push(
        {
          type: 'UsePoints',
          heading: { text: pointsRange },
          screenReaderText: t('pointsRangePerNight', {
            minPoints: hotelMinPoints,
            maxPoints: hotelMaxPoints,
          }),
        },
        { heading: { text: defaultRateMessage } }
      );
      break;
    case hasConfidentialRate:
      messages.push(
        { heading: { text: t('confidentialPrice') } },
        { heading: { text: defaultRateMessage } }
      );
      break;
    case isDiamondRate:
      messages.push(
        { heading: { text: defaultRateCaption, isCaption: true } },
        { type: 'DiamondRate', heading: { text: t('soldOutNotForYou') } },
        {
          heading: { text: defaultRateMessage },
          suffix: { text: ` | ${t('roomAvailable')}` },
        }
      );
      break;
    case usePoints && !isPointsExplorer:
      if (isPriceAvailable && pointsAvailable) getUsePointsRateInfoMessage();
      break;
  }
  // Add agebased rate info
  if (messages.length === 0) {
    const rateCaptionSuffix = getRateCaptionSuffix();
    messages.push({
      heading: { text: defaultRateCaption, isCaption: true },
      prefix: ageBasedMessage
        ? !hasSpecialRate && isPriceAvailable
          ? { text: t('ageBasedFrom') }
          : undefined
        : undefined,
      suffix: rateCaptionSuffix ? { text: rateCaptionSuffix } : undefined,
      type: ageBasedMessage && showPointsInfo ? 'UsePoints' : undefined,
    });
    if (showDisplayFeeTransparencySubMsg)
      messages.push({ heading: { text: t('rate-name-cta:includesFees') } });
    messages.push({ heading: { text: defaultRateMessage } });
    if (ageBasedMessage)
      messages.push({
        heading: {
          text: ageBasedMessage,
          infoIconOrPopup: { align: 'left', isIcon: true },
          className: 'text-xs',
        },
      });
  }

  const hotelName = hotelData?.name || '';
  let ctaMessage;
  if (showAvgPricingMessage && !showPointsInfo) {
    ctaMessage = {
      heading: { text: t('totalPrice', { totalPrice: convertedTotalRate, lengthOfStay }) },
    } as RateInfoMessage;
  }
  let customParams = {};
  // Not Bookable - call to book
  if (status === 'NOT_BOOKABLE_ONLINE') {
    customParams = {
      phoneNumber: hotelData?.contactInfo?.phoneNumber ?? '',
      isNotBookable: true,
    };
  }
  // reset message when hotel is may_be_available
  if (status === 'NOT_BOOKABLE_ONLINE' || status === 'MAY_BE_AVAILABLE') messages.length = 0;

  //NHCSEARCH-5249 MVT Compare Properties
  if (status === 'AVAILABLE' && !isIndividualRoomsOnly) customParams = { showCompare: true };

  const disclaimerMessages: RateInfoMessage[] = [];

  if (isPointsExplorer) {
    disclaimerMessages.push({
      heading: {
        text: t('rate-name-cta:pointsExplorerLegalDisclaimer'),
      },
    });
  }
  return {
    isNewHotel,
    isSaleHotel,
    ctaLabel,
    ctaHref: href,
    ctaMessage,
    customParams,
    hotelName,
    tripAdvisorLocationSummary,
    messages,
    disclaimerMessages,
  };
}
