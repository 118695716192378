/**
 * @param {String[]} arrayToChunk - string array to create subarray of string arrays
 * @param {Number} chunkSize - size of sub chunks of array
 */
export const arrayChunk = (arrayToChunk: string[], chunkSize: number) => {
  const results = [];

  while (arrayToChunk.length) {
    results.push(arrayToChunk.splice(0, chunkSize));
  }

  return results;
};
