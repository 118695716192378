import type { GuestQuery } from '@dx-ui/framework-auth-provider';
import type { QueryParameters } from '../../../constants';
import type { ShopMultiPropAvailQueryVariables } from '../../../gql/operations';
import { getGuestInfo } from '../../../utils';

export const generateShopMultiPropAvailInputVariables = ({
  guestInfo,
  queryParameters,
  shouldUsePoints = false,
  guestLocationCountry,
  smbProgramAccountId,
}: {
  guestInfo?: GuestQuery['guest'] | null;
  queryParameters?: QueryParameters;
  shouldUsePoints?: boolean | null;
  guestLocationCountry?: string;
  smbProgramAccountId: number | null;
}): ShopMultiPropAvailQueryVariables['input'] => {
  /**
   * current limitation with CP is to only compute pricing on occupancy for room 1, all other room occupancy is ignored
   * another limitation is childAges.length must equal numChildren, if childAges.length !== numChildren, send []
   */
  const [room1] = queryParameters?.rooms || [];
  const childAges =
    typeof room1?.childAges === 'number'
      ? [room1.childAges]
      : (room1?.childAges
          ?.map(({ age }) => age)
          .filter((a) => typeof a !== 'undefined') as number[]);
  const { isTMTPTeamMember, isFriendsAndFamily, isOwner, isHGVOwner, isTPHMember } =
    getGuestInfo(guestInfo);
  const tmtpRates = {
    teamMember: ((isTMTPTeamMember || isTPHMember) && queryParameters?.employeeRate) || false,
    familyAndFriends:
      ((isFriendsAndFamily || isTPHMember) && queryParameters?.friendsAndFamilyRate) || false,
    owner: (isOwner && queryParameters?.ownerVIPRate) || false,
    ownerHGV: (isHGVOwner && queryParameters?.ownerHGVRate) || false,
  };

  const smbRates = Boolean(queryParameters?.smbRate && smbProgramAccountId);
  const programAccountId = smbRates ? { programAccountId: smbProgramAccountId } : {};

  return {
    guestId: guestInfo?.guestId || 0,
    guestLocationCountry,
    arrivalDate: queryParameters?.arrivalDate || '',
    departureDate: queryParameters?.departureDate || '',
    numAdults: queryParameters?.numAdults || 1,
    numChildren: queryParameters?.numChildren,
    numRooms: queryParameters?.numRooms,
    childAges: queryParameters?.numChildren !== childAges?.length ? [] : childAges, // limitation is childAges.length must equal numChildren, if childAges.length !== numChildren, send []
    ratePlanCodes: queryParameters?.specPlan,
    rateCategoryTokens: queryParameters?.specialRateTokens,
    specialRates: {
      aaa: queryParameters?.token?.includes('HCAAA'),
      aarp: queryParameters?.token?.includes('HCARP'),
      corporateId: queryParameters?.corporateCode,
      governmentMilitary: queryParameters?.token?.includes('GDSGOV'),
      groupCode: queryParameters?.groupCode,
      hhonors: shouldUsePoints,
      pnd:
        !!queryParameters?.pnd && queryParameters?.corporateCode !== 'false'
          ? queryParameters?.pnd
          : '',
      offerId:
        !!queryParameters?.offerId && queryParameters?.promoCode !== 'false'
          ? queryParameters?.offerId
          : null,
      promoCode: queryParameters?.promoCode,
      senior: queryParameters?.token?.includes('GDSSNR'),
      smb: smbRates,
      travelAgent: queryParameters?.travelAgentRate,
      ...tmtpRates,
    },
    ...programAccountId,
  };
};
