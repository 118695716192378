import { ShopMultiPropAvailDocument, ShopMultiPropAvailPointsDocument } from '../../../gql/queries';
import type { QueryClient } from '@tanstack/react-query';
import type { ShopMultiPropAvailPointsQuery } from '../../../gql/operations';
import type {
  ShopMultiPropAvailPointsQueryVariables,
  ShopMultiPropAvailQueryInput,
} from '../../../gql/types';

export const getHashedMPADataFromCache = (
  queryClient: QueryClient,
  input: ShopMultiPropAvailQueryInput,
  usePoints: boolean
) => {
  //select between points and currency based MPA query cache key
  const queryKey =
    usePoints === true ? ShopMultiPropAvailPointsDocument : ShopMultiPropAvailDocument;
  const dataArray = queryClient.getQueriesData<ShopMultiPropAvailPointsQuery>({
    queryKey: [queryKey],
  });

  return dataArray.reduce((hashMap, cacheEntry) => {
    //first check to see if cached data actually matches current "input" variables for mpa. If it's cached data but with wrong parameters dont return it as consumable data
    if (
      JSON.stringify(input) !==
      JSON.stringify((cacheEntry?.[0]?.[1] as ShopMultiPropAvailPointsQueryVariables).input)
    ) {
      return hashMap;
    }

    //otherwise data is valid for query inputs, normalize it
    const normalizedCacheEntry = cacheEntry?.[1]?.shopMultiPropAvail?.reduce(
      (normalizedData, cacheValue) => {
        if (cacheValue?.ctyhocn) normalizedData[cacheValue?.ctyhocn] = cacheValue;
        return normalizedData;
      },
      {} as Record<string, ShopMultiPropAvailPointsQuery['shopMultiPropAvail'][0]>
    );

    //merge normalized data with other normalized data
    return { ...hashMap, ...normalizedCacheEntry };
  }, {} as Record<string, ShopMultiPropAvailPointsQuery['shopMultiPropAvail'][0]>);
};
